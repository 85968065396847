import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  PageHeader,
  BoxIntroDecor,
  BoxPoster,
  Poster,
} from "../components/styles"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

export const query = graphql`
  query {
    poster: file(relativePath: { eq: "recognition/poster.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 488, maxHeight: 564) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Recognition = ({ data }) => {
  return (
    <Layout>
      <SEO title="Recognition" />
      <Grid container alignItems="center" style={{ margin: "0 0 84px 0" }}>
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <PageHeader variant="h1">Recognition</PageHeader>
            <Typography>
              Over the years I’ve received numerous awards and accolades including:
            </Typography>
            <Typography>
              Recipient of the Young Vietnamese-Australian of the Year Award, Bankstown City Council’s Young Citizen of the Year Award, a Centenary Medal for services to the community and the arts and the 2005 Young Australian of the Year Award.
            </Typography>
            <Typography>
              Nominated for AFI Awards, Australian Writer’s Guild Awards, Film Critic’s Circle of Australia Awards, Screen Producers’ of Australia Awards, Logies. Recipient of IF Award, Australian Director’s Guild Award and multiple international film festival awards.
            </Typography>
          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPoster>
            <Poster fluid={data.poster.childImageSharp.fluid} />
          </BoxPoster>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Recognition
